import styled, { css } from "styled-components";

export const Description = styled.div`
  font-size: var(--textS);
  line-height: var(--lineHeightS);
  margin: var(--spacingXs) 0;

  p {
    margin: 0;
  }

  @media screen and (min-width: 1128px) {
    font-size: var(--textM);
    line-height: var(--lineHeightM);
  }
`;

export const MiniCardGridWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: var(--spacingS);
  width: 100%;
  max-width: 100%;
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  list-style: none;

  > * { 
    width:100%;
  }  
  @media screen and (min-width: 1128px) {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacingM);
  }
`;


export default MiniCardGridWrapper;
