import dynamic from "next/dynamic";
import EditorJSContent from "../../editor-js-content";
import { Section } from "../index";

import {
  MiniCardGridWrapper,
  Description,
} from "./styles";

const MiniCardItem = dynamic(() =>
  import("../../elements/mini-card-item-new/mini-card-item-new")
);

export const MiniCardGrid = ({
  title,
  sectionSubtitle,
  hideLineSeparation,
  description,
  miniCardItems,
  displaySetting

}) => {
  return (
    <Section
      title={title}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      {description && (
        <Description>
          <EditorJSContent content={description} />
        </Description>
      )}
      <MiniCardGridWrapper >
        {miniCardItems.map((miniCard) => (
          <MiniCardItem
            key={miniCard.id}
            {...miniCard}
          />
        ))}
      </MiniCardGridWrapper>
    </Section>
  );
};

export default MiniCardGrid;
